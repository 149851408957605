import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { Input, Button, Space } from 'antd';
import { performAction } from '../Actions';
import { SearchOutlined } from '@ant-design/icons';
import { Table, notification } from 'antd';
import { DeleteButton } from './shared/DeleteButton';


function EntityType() {
  const [entities, setEntities] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [refresh, setRefresh] = useState(false)
  const [identifier, setIdentifier] = useState('');
  const [entityId, setEntityId] = useState()
  const [editFlag, setEditFlag] = useState(false)

  const onIdentifierChange = ({target:{value}}) => setIdentifier(value)

  const notifyUser = (err_msg) => {
    notification['error']({
      message: 'Error',
      description: err_msg
    })
  }

  const resetForm = () => {
    setEntityId()
    setIdentifier('')
  }

  const handleAdd = () => {
    setEditFlag(false)
    handleShow()
    resetForm()
  }

  const handleEdit = async (e, entity) => {
    e.preventDefault()
    setEditFlag(true)
    handleShow()
    async function setForm() {
      setIdentifier(entity.identifier)
      setEntityId(entity.id)
    }
    await setForm()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      "identifier": identifier
    }
    let action;
    if(editFlag === true){
        payload["id"] = entityId
        action = 'update'
    } else {
        action = 'create'
    }
    performAction('entity-type', action, payload).then(r => {
      setRefresh(true)
      setShow(false)
      resetForm()
    }).catch(err => console.log(err.message))
  }

  useEffect(() => {
    async function fetch_entities() {
      let entities_list = await performAction('entity-type' , 'list')
      setEntities(entities_list)
    }
    fetch_entities().then(r => setRefresh(false))
  }, [refresh])


  const getRow = (entity, index) => {
    return   {
      key: index,
      id: entity,
      identifier: entity.identifier,
      action: entity.id,
    }
  }

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  var searchInput = null
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
          />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',
    });

  const columns = [
    {
      "title": 'UID',
      "dataIndex": 'id',
      "key": 'id',
      "render": entity => <a href="" onClick={(e) => handleEdit(e, entity)}>{entity.id}</a>
    },
    {
      "title": "Identifier",
      "dataIndex": "identifier",
      "key": "identifier",
      ...getColumnSearchProps('identifier')
    },
    {
      "title": "Action",
      "key": "action",
      "render": entity => <DeleteButton entity="entity-type" entity_label="Entity Type" entityId={entity.action} onSuccess={setRefresh}/>
    }
  ]

  const data = entities.map(getRow)

  return (
      <>
        <Row>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>List of Entities</h3>
            <Button size="sm" variant="outline-primary" onClick={handleAdd}>
              Add Entity
            </Button>
          </div>

          <Table columns={columns} dataSource={data}/>

        </Row>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Entity</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Identifier</Form.Label>
                <Form.Control type="email" value={identifier} placeholder="Enter identifier" onChange={onIdentifierChange}/>
                <Form.Text className="text-muted">
                  Eg : Item, Category, Option Group
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </>
  )
}

export default EntityType