import React, {useEffect, useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

import {performAction} from '../Actions';
import { Table } from 'antd';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DeleteButton } from './shared/DeleteButton';


function Field() {
  const [fieldTypes, setFieldTypes] = useState([]);
  const [entities, setEntities] = useState([])
  const [fields, setFields] = useState([]);
  const [menuTypes, setMenuTypes] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [path, setPath] = useState('');
  const [refresh, setRefresh] = useState(false)
  const [fieldTypeId, setFieldTypeId] = useState()
  const [entityTypeId, setEntityTypeId] = useState()
  const [menuTypeId, setMenuTypeId] = useState()
  const [fieldId, setFieldId] = useState()
  const [editFlag, setEditFlag] = useState(false)
  
  const onPathChange = ({target:{value}}) => setPath(value)
  const onFieldTypeSelect = ({target:{value}}) => setFieldTypeId(value)
  const onEntityTypeSelect = ({target:{value}}) => setEntityTypeId(value)
  const onMenuTypeSelect = ({target:{value}}) => setMenuTypeId(value)
  
  const resetForm = () => {
    setMenuTypeId()
    setFieldTypeId()
    setFieldId()
    setEntityTypeId()
    setPath()
  }
  const handleAdd = () => {setEditFlag(false); handleShow(); resetForm()}

  const handleEdit = async (e, field) => {
    e.preventDefault()
    setEditFlag(true)
    handleShow()
    async function setForm() {
      setFieldId(field.id)
      setPath(field.path)
      if(field.menu_type) {setMenuTypeId(field.menu_type.id)}
      if(field.entity_type) {setEntityTypeId(field.entity_type.id)}
      if(field.field_type) {setFieldTypeId(field.field_type.id)}
    }
    await setForm()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      "path": path,
      "menu_type_id": menuTypeId,
      "entity_type_id": entityTypeId,
    }
    if(fieldTypeId !== "-1"){
      payload["field_type_id"] = fieldTypeId
    }
    let action;
    if(editFlag === true) {
      payload["id"] = fieldId
      action = "update"
    } else {
      action = "create"
    }
    performAction('field', action, payload).then(r => {
      setRefresh(true)
      setEditFlag(false)
      resetForm()
      handleClose()
    })
  }

  useEffect(() => {
    async function fetch_menu_types() {
      let menutypes_list = await performAction('menu-type', 'list')
      setMenuTypes(menutypes_list)
    }
    fetch_menu_types()
    }, [])

  useEffect(() => {
    async function fetch_entities() {
        let entities_list = await performAction('entity-type', 'list')
        setEntities(entities_list)
    }
    fetch_entities()
    }, [])


  useEffect(() => {
    async function fetch_field_types() {
      let fieldtypes_list = await performAction('field-type', 'list')
      setFieldTypes(fieldtypes_list)
    }
    fetch_field_types()
}, [])

  useEffect(() => {
    async function fetch_fields() {
      let fields_list = await performAction('field', 'list')
      setFields(fields_list)
    }
    fetch_fields().then(r => setRefresh(false))
}, [refresh])


  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  var searchInput = null

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
          />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',
    });
    


  const getRow = (field, index) => {
    return   {
      key: index,
      id: field,
      menuType: field.menu_type.identifier,
      entityType: field.entity_type.identifier,
      fieldType: field.field_type && field.field_type.identifier,
      path: field.path,
      action: field.id
    }
  }

  const columns = [
    {
      "title": 'UID',
      "dataIndex": 'id',
      "key": 'id',
      "render": field => <a href="" onClick={(e) => handleEdit(e, field)}>{field.id}</a>
    },
    {
      "title": "Menu Type",
      "dataIndex": "menuType",
      "key": "menuType",
      "filters": menuTypes.map((menuType) => {return {"text": menuType.identifier, "value": menuType.identifier}}),
      "onFilter": (value, record) => record.menuType.includes(value),
      "sorter": (a, b) => a.menuType < b.menuType,
      "sortDirections": ['descend'],
    },
    {
      "title": "Entity",
      "dataIndex": "entityType",
      "key": "entityType",
      "filters": entities.map((entityType) => {return {"text": entityType.identifier, "value": entityType.identifier}}),
      "onFilter": (value, record) => record.entityType.includes(value),
      "sorter": (a, b) => a.entityType < b.entityType,
      "sortDirections": ['descend'],
    },
    {
      "title": "Field",
      "dataIndex": "fieldType",
      "key": "fieldType",
      ...getColumnSearchProps('fieldType'),
    },
    {
      "title": "Path",
      "dataIndex": "path",
      "key": "path",
      ...getColumnSearchProps('path'),
    },
    {
      "title": "Action",
      "key": "action",
      "render": (field) => <DeleteButton entity="field" entity_label="Field" entityId={field.action} onSuccess={setRefresh}/>
    }
  ]

  const data = fields.map(getRow)


      return (
        <>
        <Row>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>List of Fields</h3>
            <Button size="sm" variant="outline-primary" onClick={handleAdd}>
              Add Field
            </Button>
          </div>
          <Table columns={columns} dataSource={data}/>
        </Row>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Field</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Path</Form.Label>
              <Form.Control type="text" value={path} placeholder="Enter payload path" onChange={onPathChange}/>
              <Form.Text className="text-muted">
                Eg : payload["item"]["title"]
              </Form.Text>
            </Form.Group>
            <Form.Select value={menuTypeId} onChange={onMenuTypeSelect}>
              <option value={-1}>Select Menu Type</option>
              {menuTypes.map((menuType, idx) => {
                  return <option value={menuType.id}>{menuType.title}</option>
              })}
            </Form.Select>
            <br/>
              <Form.Select value={entityTypeId} onChange={onEntityTypeSelect}>
              <option value={-1}>Select Entity Type</option>
              {entities.map((entityType, idx) => {
                  return <option value={entityType.id}>{entityType.identifier}</option>
              })}
            </Form.Select>
            <br/>
            <Form.Select value={fieldTypeId} onChange={onFieldTypeSelect}>
              <option value={-1}>Select Field Type</option>
              {fieldTypes.map((fieldType, idx) => {
                  return <option value={fieldType.id}>{fieldType.identifier}</option>
              })}
            </Form.Select>
            <br/>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </Form>
          </Offcanvas.Body>
        </Offcanvas>
        </>
      )
    }

export default Field