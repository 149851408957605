import axios from 'axios';
import { DEFAULT_PAGE_SIZE } from 'global_constants';
import qs from 'qs'

export const API_BASE = process.env.REACT_APP_API_BASE;

export const performAction = async (objectType, action, data=null) => {
    try {
        let method,
            url = `${API_BASE}` + objectType;
        if (action === 'list') {
            method = axios.get
        } else if (action === 'create') {
            method = axios.post
        } else if (action === 'update') {
            method = axios.put
            url += `/` + data['id']
        } else if (action === 'delete') {
            method = axios.delete
            url += `/` + data['id']
        }
        else {
            method = axios.put
            url += '/' + action + '/' + data['id']
        }

        const resp = await method(url, data);
        return resp.data;
    } catch (error) {
        throw error;
    }
}

export const getPaginatedData = async(objectType, queryParams_obj={'offset': 0, 'limit': DEFAULT_PAGE_SIZE})=>{
    try
    {
        let queryParams = new URLSearchParams(queryParams_obj).toString()
        const url = `${API_BASE}` + objectType + `?${queryParams}`;
        const resp = await axios.get(url);
        return resp.data;
    } catch (error) {
        throw error;
    }
}

export const validatePayload = async (data) => {
    try {
      const url = `${API_BASE}` + `validate`;
      const resp = await axios.post(url, data);
      return resp.data;
    } catch (error) {
        throw error;
    }
}

export const fetchOperators = async () => {
    try {
        const url = `${API_BASE}` + `operators`;
        const resp = await axios.get(url);
        return resp.data;
    } catch (error) {
        throw error;
    }
}

export const fetchVersions = async (entity_type, entity_id) => {
    try {
        const url = `${API_BASE}versions/` + entity_type + '/' + entity_id;
        const resp = await axios.get(url);
        return resp.data;
    } catch (error) {
        throw error;
    }
}

export const fetchDataForMenuLog = async (id, req_data=null) => {
    try {
        const url = `${API_BASE}` + `verification-requests/` + id + `/` + req_data;
        const resp = await axios.get(url);
        return resp.data;
    } catch (error) {
        throw error;
    }
}

export const loginUser = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${API_BASE}` + `auth`,
            data: qs.stringify(data),
            headers: {
              'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          })
        return resp.data;
    } catch (error) {
        throw error
    }
}

export const gLoginUser = async (tokenId) => {
    try {
        const resp = await axios({
            method: 'get',
            url: `${API_BASE}` + `g_auth?tokenId=${tokenId}`
          })
        return resp.data;
    } catch (error) {
        throw error
    }
}

export const markPayloadAsSample = async (uuid, action) => {
    try {
        const action_val = action == true ? "enable" : "disable"
        const data = {
            "uuid": uuid,
            "action": action_val,
        }
        const resp = await axios.post(`${API_BASE}` + `verification-requests/mark_as_sample`, data)
        return resp.data;
    } catch (error) {
        throw error
    }
}

export const runTests = async (menuLogIds, ruleInfo) => {
    try {
        const data = {
            "menu_log_ids": menuLogIds,
            "rule": ruleInfo,
        }
        const resp = await axios.post(`${API_BASE}` + `rule/validate`, data)
        return resp.data;
    } catch (error) {
        throw error
    }
}
