import 'App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes, Navigate} from "react-router-dom";
import React, { useState } from 'react';
import Home from 'containers/Home'
import EntityType from 'containers/EntityType'
import MenuType from 'containers/MenuType';
import FieldType from 'containers/FieldType';
import Field from 'containers/Field';
import RuleType from 'containers/RuleType';
import RuleSet from 'containers/RuleSet';
import Rule from 'containers/Rule';
import FieldSet from 'containers/FieldSet';
import Validator from 'containers/Validator';
import Validation from 'containers/Validation';
import DashboardLayout from 'containers/DashboardLayout';
import LandingPage from './containers/LandingPage';
import MenuLog from './containers/MenuLog';
import MenuLogDetails from './containers/MenuLogDetails';
import { lStorage } from './utils/auth';


export const AuthorizationContext  = React.createContext();

function App() {

  const [token, setToken] = useState(lStorage.get('auth'))
  const [isAdmin, setIsAdmin] = useState(lStorage.get('is_admin'))
  const [username, setUsername] = useState(lStorage.get('username'))

  const user = {
    "username": username,
    "is_admin": isAdmin,
  }

  const setUserCreds = (token, username, is_admin) => {
    setToken(token)
    setUsername(username)
    setIsAdmin(is_admin)
  }

  if(!token){
    return <LandingPage setUserCreds={setUserCreds}/>
  }

  return (
    <AuthorizationContext.Provider value={user}>
        <Routes>
          <Route path="/" element={<DashboardLayout username={username}/>}>
            <Route index element={<Home />} />
            {user.is_admin &&
            <>
            <Route exact path="/entity" element={<EntityType />} />
            <Route exact path="/menutype" element={<MenuType />} />
            <Route exact path="/fieldtype" element={<FieldType />} />
            <Route exact path="/ruletype" element={<RuleType />} />
            </>
            }
            <Route exact path="/field" element={<Field />} />
            <Route exact path="/rule" element={<Rule />} />
            <Route exact path="/ruleset" element={<RuleSet />} />
            <Route exact path="/fieldset" element={<FieldSet />} />
            <Route exact path="/validator" element={<Validator />} />
            <Route exact path="/validate" element={<Validation />} />
            <Route exact path="/logs" element={<MenuLog />} />
            <Route exact path="/logs-details/:id/:type" element={<MenuLogDetails />} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Route>
        </Routes>
    </AuthorizationContext.Provider>
  );
}



export default App;
