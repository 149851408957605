import React, {useEffect, useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col';
import {FaTimesCircle} from "react-icons/fa";
import { Input, Button, Space } from 'antd';
import {performAction} from '../Actions';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Select } from 'antd';
import { DeleteButton } from './shared/DeleteButton';

const { Option } = Select;

function FieldSet() {
    const [fields, setFields] = useState([])
    const [fieldSets, setFieldSets] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [refresh, setRefresh] = useState(false)
    
    const [editFlag, setEditFlag] = useState(false)
    const [fieldSetId, setFieldSetId] = useState()
    
    // track form elements
    const [description, setDescription] = useState('')
    const [fieldIds, setFieldIds] = useState([])
    const [baseFieldSetId, setBaseFieldSetId] = useState()
    const onBaseFieldSelectionChange = ({target:{value}}) => setBaseFieldSetId(value)

    const resetForm = () => {
        setFieldSetId()
        setFieldIds([])
        setDescription()
        setBaseFieldSetId()
    }
    const handleAdd = () => {setEditFlag(false); handleShow(); resetForm()}

    const handleEdit = async (e, fieldset) => {
        e.preventDefault()
        handleShow()
        setEditFlag(true)
        async function setForm() {
            let field_ids = Array.from(fieldset.fields, field => field.id)
            setFieldSetId(fieldset.id)
            setFieldIds(field_ids)
            setDescription(fieldset.description)
            if(fieldset.base_field_set){
                setBaseFieldSetId(fieldset.base_field_set.id)
            }
        }
        await setForm()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let payload = {
            "description": description,
            "field_ids": fieldIds.map((val) => Number(val)),
        }
        if(baseFieldSetId !== "-1"){
            payload["base_field_set_id"] = baseFieldSetId
        }
        let action
        if(editFlag === true){
            payload["id"] = fieldSetId
            action = "update"
        } else {
            action = "create"
        }
        performAction('field-set', action, payload).then(r => {
            setRefresh(true)
            handleClose()
            setEditFlag(false)
            resetForm()
        })
    }

    useEffect(() => {
        async function fetch_fields() {
            let fields_list = await performAction('field', 'list')
            setFields(fields_list)
        }
        fetch_fields()
    }, [])

    useEffect(() => {
        async function fetch_fieldsets() {
            let fieldset_list = await performAction('field-set', 'list')
            setFieldSets(fieldset_list)
        }
        fetch_fieldsets().then(r => setRefresh(false))
    }, [refresh])

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    var searchInput = null

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    };
  
    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('')
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
              />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
        });

    const getRow = (fieldSet, index) => {
        return   {
          key: index,
          id: fieldSet,
          description: fieldSet.description,
          fields: fieldSet,
          baseFieldSet: fieldSet.base_field_set,
          action: fieldSet.id,
        }
    }

    const columns = [
        {
          "title": 'UID',
          "dataIndex": 'id',
          "key": 'id',
          "render": fieldSet => <a href="" onClick={(e) => handleEdit(e, fieldSet)}>{fieldSet.id}</a>
        },
        {
          "title": "Description",
          "dataIndex": "description",
          "key": "description",
          ...getColumnSearchProps('description')
        },
        {
            "title": "Base Fieldset",
            "dataIndex": "baseFieldSet",
            "key": "baseFieldSet",
            "render": baseFieldSet => baseFieldSet && <p>ID : {baseFieldSet.id}<br/> Description : {baseFieldSet.description}</p>
        },
        {
          "title": "Action",
          "key": "action",
          "render": (fieldSet) => <DeleteButton entity="field-set" entity_label="Fieldset" entityId={fieldSet.action} onSuccess={setRefresh}/>
        }
      ]
      const data = fieldSets.map(getRow)


    return (
        <>
            <Row>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <h3>List of Fieldsets</h3>
                    <Button size="sm" variant="outline-primary" onClick={handleAdd}>
                        Add Fieldset
                    </Button>
                </div>
                <Table 
                    columns={columns} 
                    expandable={{
                        expandedRowRender: record => record.fields.fields.map((field, idx) => {
                            return <p style={{fontSize:"13px"}}><strong>{field.entity_type.identifier}{field.field_type && "-"+field.field_type.identifier}</strong>: {field.path}</p>
                        }),
                        rowExpandable: record => record.name !== 'Not Expandable',
                      }}
                    dataSource={data}/>
            </Row>

            <Offcanvas show={show} onHide={handleClose} placement="end" style={{minWidth:"1000px"}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Fieldset</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" value={description} placeholder="Describe your set of fields" onChange={e => setDescription(e.target.value)}/>
                            <Form.Text className="text-muted">
                                Eg : collection of zomato fields
                            </Form.Text>
                        </Form.Group>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please Select Fields"
                            defaultValue={fieldIds.map(id => id.toString())}
                            onChange={(values) => setFieldIds(values.map(val => parseInt(val)))}>
                                {fields.map((field, idx) => {
                                    let entity_identifier = field.entity_type ? field.entity_type.identifier + "-" : ""
                                    let field_identifier = field.field_type ? field.field_type.identifier + "-" : ""
                                    let menu_identifier = field.menu_type ? field.menu_type.identifier : ""
                                    return <Option key={field.id}>{entity_identifier}{field_identifier}{menu_identifier}</Option>
                                })}

                        </Select>
                        {/* <Form.Group as={Col} controlId="my_multiselect_field1">
                            <Form.Label>Select Fields</Form.Label>
                            <Form.Control htmlSize={10} as="select" value={fieldIds} multiple onChange={e => setFieldIds([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                                {fields.map((field, idx) => {
                                    let entity_identifier = field.entity_type ? field.entity_type.identifier + "-" : ""
                                    let field_identifier = field.field_type ? field.field_type.identifier + "-" : ""
                                    let menu_identifier = field.menu_type ? field.menu_type.identifier : ""
                                    return <option value={field.id}>{entity_identifier}{field_identifier}{menu_identifier}</option>
                                })}
                            </Form.Control>
                        </Form.Group > */}
                        <Form.Group style={{marginTop:"20px"}}>
                            <Form.Select value={baseFieldSetId} onChange={onBaseFieldSelectionChange}>
                                <option value={-1}>Select Base Fieldset(if any)</option>
                                {fieldSets.map((fieldSet, idx) => {
                                    return <option value={fieldSet.id}>{fieldSet.description}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={handleSubmit} style={{marginTop:"20px"}}>
                            Submit
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default FieldSet


function getFieldBaseSetInfo(fieldset) {
    let final_text = ''
    if(fieldset != null) {
        final_text += fieldset['id']
    }
    return final_text
}