import React, { useEffect } from 'react';
import axios from 'axios';
import { gLoginUser } from '../Actions';
import { lStorage } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Typography } from 'antd';
import { GoogleLogin} from 'react-google-login';
import { gapi } from 'gapi-script';


// extract components
const { Text, Paragraph } = Typography;
const { Content } = Layout;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const LandingPage = ({setUserCreds}) => {
  const navigate = useNavigate()

  useEffect(() => navigate('/'), [])

  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: ''
      });
    };
    gapi.load('auth2', initClient);
  })

  const onSuccess = (res) => {
    const username = res.profileObj?.name
    const tokenId = res.tokenId
    if (tokenId) {
      gLoginUser(tokenId)
        .then(auth_res => {
          const access_token = auth_res.access_token
          const is_admin = auth_res.is_admin
          lStorage.set('auth', access_token)
          lStorage.set('username', username)
          lStorage.set('is_admin', is_admin)
          setUserCreds(access_token, username, is_admin)
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        })
        .catch(error => {
          window.alert("User is not registered or inactive")
        })
    } else {
      window.alert("Unable to log in.\nError occurred in Google Signin.")
    }
  };

  const onFailure = (err) => {
    window.alert("Unable to log in.\nError occurred in Google Signin.")
  };

  const LoginForm = () => (
    <GoogleLogin
      className='gbutton'
      clientId={GOOGLE_CLIENT_ID}
      buttonText="Sign in with Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
    />
  )

  return (
    <Layout className="login-page-container">
    <Content>
      <Row type="flex" justify="center" align="middle">
        <Col span={6} className="login-container">
            <Typography className="login-form-header">
              <div id="prism-logo">
                P R I S M
              </div>
              <Paragraph>
                <Text>UrbanPiper's Menu Service</Text>
              </Paragraph>
            </Typography>
            <div className='gbutton-container'>
              <LoginForm/>
            </div>
        </Col>
      </Row>
    </Content>
    </Layout>
  );
}

export default LandingPage;
