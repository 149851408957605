import React, {useEffect, useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import {FaTimesCircle} from "react-icons/fa";

import {performAction} from '../Actions';
import { SearchOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { Input, Button, Space } from 'antd';
import { DeleteButton } from './shared/DeleteButton';


function FieldType() {
  const [fieldTypes, setFieldTypes] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [identifier, setIdentifier] = useState('');
  const [refresh, setRefresh] = useState(false)
  const [entityTypeId, setEntityTypeId] = useState()
  const [entities, setEntities] = useState([])
  const [fieldTypeId, setFieldTypeId] = useState()
  const [editFlag, setEditFlag] = useState(false)
  
  const onIdentifierChange = ({target:{value}}) => setIdentifier(value)
  const onEntitySelect = ({target:{value}}) => setEntityTypeId(value)
  const resetForm = () => {
    setIdentifier('')
    setFieldTypeId()
  }
  const handleAdd = () => {setEditFlag(false); handleShow(); resetForm()}
  const handleEdit = async (e, fieldType) => {
    e.preventDefault()
    setEditFlag(true)
    setShow(true)
    async function setForm() {
      setIdentifier(fieldType.identifier)
      setFieldTypeId(fieldType.id)
    }
    await setForm()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      "identifier": identifier,
      "entity_type_id": entityTypeId,
    }
    let action;
    if(editFlag === true){
      payload["id"] = fieldTypeId
      action = 'update'
    }
    else{
      action = 'create'
    }
    performAction('field-type', action, payload).then(r => {
      setRefresh(true)
      resetForm()
      handleClose()
    })
  }

  useEffect(() => {
    async function fetch_entities() {
      let entities_list = await performAction('entity-type', 'list')
      setEntities(entities_list)
    }
    fetch_entities()
  }, [])

  useEffect(() => {
    async function getEntities() {
      let fieldtypes_list = await performAction('field-type', 'list')
      setFieldTypes(fieldtypes_list)
    }
    getEntities().then(r => setRefresh(false))
  }, [refresh])

  
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  var searchInput = null
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
          />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',
    });
      
      const getRow = (fieldType, index) => {
        return   {
          key: index,
          id: fieldType,
          identifier: fieldType.identifier,
          action: fieldType.id,
        }
      }
    
      const columns = [
        {
          "title": 'UID',
          "dataIndex": 'id',
          "key": 'id',
          "render": fieldType => <a href="" onClick={(e) => handleEdit(e, fieldType)}>{fieldType.id}</a>
        },
        {
          "title": "Identifier",
          "dataIndex": "identifier",
          "key": "identifier",
          ...getColumnSearchProps('identifier')
        },
        {
          "title": "Action",
          "key": "action",
          "render": (fieldType) => <DeleteButton entity="field-type" entity_label="FieldType" entityId={fieldType.action} onSuccess={setRefresh}/>
        }
      ]
    
      const data = fieldTypes.map(getRow)
      
  return (
      <>
        <Row>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>List of Field Types</h3>
            <Button size="sm" variant="outline-primary" onClick={handleAdd}>
              Add Field Type
            </Button>
          </div>

          <Table columns={columns} dataSource={data}/>

        </Row>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Field Type</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Identifier</Form.Label>
                <Form.Control type="text" value={identifier} placeholder="Enter identifier" onChange={onIdentifierChange}/>
                <Form.Text className="text-muted">
                  Eg : price, discount
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </>
  )
}

export default FieldType
