import {Outlet, Link} from "react-router-dom";
import React, { useState, useContext } from 'react';

import { logout } from '../utils/auth';

import { Layout, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { AuthorizationContext } from '../App'

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;


function DashboardLayout({username}) {
    const [ selectedMenuItem, setSelectedMenuItem ] = useState()
    const user = useContext(AuthorizationContext)

    const userMenu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="" onClick={() => logout()}>
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
      <Sider style={{backgroundColor:"#001529"}}
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div id="logo">
          <Link to="/" onClick={() => setSelectedMenuItem()}>P R I S M</Link>
        </div>
        <Menu theme="dark" mode="inline" id="navbar"
            defaultOpenKeys={['sub1']}
            selectedKeys={[selectedMenuItem]}
            onClick={(e) => setSelectedMenuItem(e.key)} inlineIndent={15}>
            <SubMenu key="sub1" title="Validation Service">
                {user.is_admin && <>
                    <Menu.Item key="1">
                      <Link to="/entity">Entity Types</Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <Link to="/fieldtype">Field Types</Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                      <Link to="/menutype">Menu Types</Link>
                    </Menu.Item>
                    <Menu.Item key="6">
                        <Link to="/ruletype">Rule Types</Link>
                    </Menu.Item>
                    </> 
                }
                <Menu.Item key="4">
                  <Link to="/field">Fields</Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/fieldset">Fieldsets</Link>
                </Menu.Item>

                <Menu.Item key="7">
                  <Link to="/rule">Rules</Link>
                </Menu.Item>
                <Menu.Item key="8">
                  <Link to="/ruleset">Rulesets</Link>
                </Menu.Item>
                <Menu.Item key="9">
                  <Link to="/validator">Validators</Link>
                </Menu.Item>
                <Menu.Item key="10">
                  <Link to="/validate">Menu Validation</Link>
                </Menu.Item>
                <Menu.Item key="11">
                  <Link to="/logs">Validation Logs</Link>
                </Menu.Item>
            </SubMenu>
            {/* <SubMenu key="sub2" title="Publish Service">
                <Menu.Item key="11">
                  <Link to="#">Coming Soon!</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" title="Parity Service">
                <Menu.Item key="12">
                  <Link to="#">Coming Soon!</Link>
                </Menu.Item>
            </SubMenu> */}
        </Menu>
      </Sider>
      <Layout>
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
          <Dropdown overlay={userMenu}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Hi {username} <DownOutlined />
            </a>
          </Dropdown>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Outlet/>
          </div>
        </Content>
      </Layout>
    </Layout>
    );
  }

export default DashboardLayout