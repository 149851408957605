import React, {useEffect, useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import {FaTimesCircle} from "react-icons/fa";
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {performAction} from '../Actions';
import { Table, Select } from 'antd';
import { PUBLISHED_VERSION } from 'global_constants';
import { DeleteButton } from './shared/DeleteButton';

const { Option } = Select;


function RuleSet() {

    const [rules, setRules] = useState([])
    const [ruleTypes, setRuleTypes] = useState([])
    const [ruleSets, setRuleSets] = useState([])
    const [ruleIdentifiers, setRuleIdentifiers] = useState([])

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [refresh, setRefresh] = useState(false)
    const [identifier, setIdentifier] = useState('')
    const [ruleSetId, setRuleSetId] = useState()
    const [editFlag, setEditFlag] = useState(false)
    
    const onIdentifierChange = ({target:{value}}) => setIdentifier(value)
    // track form elements
    const [excludedRuleIdentifierIds, setExcludedRuleIdentifierIds] = useState([])
    const [ruleIds, setRuleIds] = useState([])
    const [baseRuleSetId, setBaseRuleSetId] = useState()
    
    const onBaseRuleSelectionChange = ({target:{value}}) => setBaseRuleSetId(value)
    const resetForm = () => {
        setIdentifier('')
        setExcludedRuleIdentifierIds([])
        setBaseRuleSetId()
        setRuleIds([])
    }
    const handleAdd = () => {setEditFlag(false); handleShow(); resetForm()}
    const handleEdit = async (e, ruleset) => {
        e.preventDefault()
        handleShow()
        setEditFlag(true)
        async function setForm() {
            setRuleSetId(ruleset.id)
            setIdentifier(ruleset.identifier)
            let rule_ids = Array.from(ruleset.rules, rule => rule.id)
            setRuleIds(rule_ids)
            if(ruleset.base_rule_set){
                setBaseRuleSetId(ruleset.base_rule_set.id)
            }
            let exluded_rule_identifiers = Array.from(ruleset.excluded_rule_identifiers, _ => _.id)
            setExcludedRuleIdentifierIds(exluded_rule_identifiers)
        }
        await setForm()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let payload = {
            "identifier": identifier,
            "rule_ids": ruleIds,
            "excluded_rule_identifier_ids": excludedRuleIdentifierIds,
        }
        if(baseRuleSetId !== '-1') payload['base_rule_set_id'] = baseRuleSetId
        let action
        if(editFlag === true){
            payload["id"] = ruleSetId
            action = "update"
        } else {
            action = "create"
        }
        performAction('rule-set', action, payload).then(r => {
            setRefresh(true)
            setEditFlag(false)
            resetForm()
            handleClose()
        })
    }

    useEffect(() => {
        async function fetch_rule_types() {
            let ruletypes_list = await performAction('rule-type', 'list')
            setRuleTypes(ruletypes_list)
        }
        fetch_rule_types()
    }, [])

    useEffect(() => {
        async function fetch_rules() {
            let rules_list = await performAction(`rule?status=${PUBLISHED_VERSION}`, 'list')
            setRules(rules_list)
        }
        fetch_rules()
    }, [])

    useEffect(() => {
        async function fetch_rule_identifiers() {
            let rule_identifier_list = await performAction('rule-identifier', 'list')
            setRuleIdentifiers(rule_identifier_list)
        }
        fetch_rule_identifiers()
    }, [])

    useEffect(() => {
        async function fetch_rulesets() {
            let ruleset_list = await performAction('rule-set', 'list')
            setRuleSets(ruleset_list)
        }
        fetch_rulesets().then(r => setRefresh(false))
    }, [refresh])

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    var searchInput = null
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    };
  
    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('')
    };
  
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
      });

    const getRow = (ruleSet, index) => {
        return   {
          key: index,
          id: ruleSet,
          identifier: ruleSet.identifier,
          rules: ruleSet.rules,
          baseRuleSet: ruleSet.base_rule_set,
          excludedRuleIdentifiers: ruleSet.excluded_rule_identifiers,
          action: ruleSet.id,
        }
    }

    const formRuleColumnText = (rule) => {
        let final_text = 'ID: ' + rule.id
        if(rule.conditions) {
            for(let i = 0; i < rule.conditions.length; i++){
                const condition = rule.conditions[i]
                let field = prepareFieldIdentifier(condition.identifier)
                final_text += ', ' + field
            }
        }
        return <p>{final_text}</p>
    }

    const formRuleForDropdown = (rule) => {
        let final_text = 'ID: ' + rule.id
        if(rule.conditions) {
            for(let i = 0; i < rule.conditions.length; i++){
                const condition = rule.conditions[i]
                let field = prepareFieldIdentifier(condition.identifier)
                final_text += ', ' + field
            }
        }
        return <Option key={rule.id}>{final_text}</Option>
    }

    const columns = [
        {
          "title": 'UID',
          "dataIndex": 'id',
          "key": 'id',
          "render": ruleSet => <a href="" onClick={(e) => handleEdit(e, ruleSet)}>{ruleSet.id}</a>
        },
        {
          "title": "Identifier",
          "dataIndex": "identifier",
          "key": "identifier",
          ...getColumnSearchProps('identifier'),
        },
        {
            "title": "Base Ruleset",
            "dataIndex": "baseRuleSet",
            "key": "baseRuleSet",
            "render": baseRuleSet => baseRuleSet && <p>{baseRuleSet.identifier}</p>
        },
        {
            "title": "Excluded RuleTypes",
            "dataIndex": "excludedRuleIdentifiers",
            "key": "excludedRuleIdentifiers",
            "render": excludedRuleIdentifiers => excludedRuleIdentifiers.map((identifier, idx) => {
                        return <p>{identifier.rule_type.identifier} - {identifier.entity_type.identifier}
                                {identifier.field_type && "-"+identifier.field_type.identifier}</p>
                        })
        },
        {
          "title": "Action",
          "key": "action",
          "render": (ruleSet) => <DeleteButton entity="rule-set" entity_label="Ruleset" entityId={ruleSet.action} onSuccess={setRefresh}/>
        }
      ]
      const data = ruleSets.map(getRow)

    return (
        <>
            <Row>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <h3>List of Rulesets</h3>
                    <Button size="sm" variant="outline-primary" onClick={handleAdd}>
                        Add Ruleset
                    </Button>
                </div>
                <Table 
                    columns={columns}     
                    expandable={{
                        expandedRowRender: record => record.rules.map((rule, idx) => {
                            if(rule.rule_kind === 'simple'){
                                return formRuleColumnText(rule)
                            }
                      else return <p>ID : {rule.id}, {rule.identifier && rule.identifier.entity_type.identifier}-
                          {rule.identifier && rule.identifier.field_type && rule.identifier.field_type.identifier}-
                          {rule.identifier && rule.identifier.rule_type && rule.identifier.rule_type.identifier}</p>
                      }),
                        rowExpandable: record => record.name !== 'Not Expandable',
                    }} 
                    dataSource={data}/>
            </Row>

            <Offcanvas show={show} onHide={handleClose} placement="end" style={{minWidth:"1300px"}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Ruleset</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Identifier</Form.Label>
                            <Form.Control value={identifier} placeholder="Enter identifier" onChange={onIdentifierChange}/>
                            <Form.Text className="text-muted">
                                Eg : zomato_ruleset, base_ruleset
                            </Form.Text>
                        </Form.Group>

                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please Select Rules"
                            defaultValue={ruleIds.map(id => id.toString())}
                            onChange={(values) => setRuleIds(values.map(val => parseInt(val)))}>
                            {rules.map((rule, idx) => {
                                    if(rule.rule_kind === 'simple'){
                                        return formRuleForDropdown(rule) 
                                    } else
                                    return <Option key={rule.id}>ID : {rule.id}, {rule.identifier && rule.identifier.entity_type.identifier}-
                                        {rule.identifier && rule.identifier.field_type && rule.identifier.field_type.identifier}-
                                        {rule.identifier && rule.identifier.rule_type && rule.identifier.rule_type.identifier}</Option>
                                })}

                        </Select>

                        <Form.Group style={{marginTop:"20px"}}>
                            <Form.Select value={baseRuleSetId} onChange={onBaseRuleSelectionChange}>
                                <option value="-1">Select Base Ruleset(if any)</option>
                                {ruleSets.map((ruleset, idx) => {
                                    return <option value={ruleset.id}>{ruleset.identifier}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId="my_multiselect_field2" style={{marginTop:"20px"}}>
                            <Form.Label>Select Excluded Ruletypes</Form.Label>
                            <Form.Control value={excludedRuleIdentifierIds} as="select" multiple onChange={e => setExcludedRuleIdentifierIds([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                                {ruleIdentifiers.map((identifier, idx) => {
                                    return <option value={identifier.id}>{formRuleIdentifierLabel(identifier)}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RuleSet


function formRuleIdentifierLabel(identifier){
    let identifier_label = identifier.rule_type.identifier
    identifier_label += ' for ' + identifier.entity_type.identifier
    if(identifier.field_type){
        identifier_label += '-' + identifier.field_type.identifier
    }
    return identifier_label
}

function prepareFieldIdentifier(field) {
    let fieldIdentifier = field.entity_type.identifier
    if(field.field_type){
        fieldIdentifier += "-" + field.field_type.identifier
    }
    return fieldIdentifier
}