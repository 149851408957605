// third party
import axios from 'axios';
import { notification } from 'antd';


export const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: msg,
    });
};


export const initApp = () => {
  // set authorization header if logged in
  if (isLoggedin()) {
    const token = lStorage.get('auth');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

// localStorage utility methods
export const lStorage = {
  get: (key) => {
    const ls = localStorage.getItem('validation_service') && JSON.parse(localStorage.getItem('validation_service'));
    if (ls && ls[key]) {
      return ls[key];
    } else {
      return null;
    }
  },
  set: (key, value) => {
    const ls = localStorage.getItem('validation_service') && JSON.parse(localStorage.getItem('validation_service'));
    if (ls) {
      ls[key] = value;
      localStorage.setItem('validation_service', JSON.stringify(ls));
    } else {
      let data = {};
      data[key] = value;
      localStorage.setItem('validation_service', JSON.stringify(data));
    }
  },
  remove: (key) => {
    const ls = localStorage.getItem('validation_service') && JSON.parse(localStorage.getItem('validation_service'));
    if (ls) {
      delete (ls[key]);
      localStorage.setItem('validation_service', JSON.stringify(ls));
    }
    return true;
  }
}

export const isLoggedin = () => {
  if (lStorage.get('auth')) {
    return true;
  } else {
    return false;
  }
}

const removeAuth = () => {
  lStorage.remove('auth');
  lStorage.remove('is_admin');
  lStorage.remove('username');
  axios.defaults.headers.common['Authorization'] = '';
}

export const logout = async () => {
  try {
    removeAuth();
    window.location.reload()
  } catch (error) {
    console.log(error);
  }
}

// add an axios response interceptor
axios.interceptors.response.use(function (response) {
  // do something with response and then return it
  return response;
}, function (error) {
  // handle some status codes and then a rejected promise
  console.log('error interceptor', error, error.response);
  openNotificationWithIcon('error', 
    error.response.statusText ? error.response.statusText : "There was an error while processing your request")
  const { status } = error.response;
  if ([401].indexOf(status) > -1) {
    logout();
  }
  return Promise.reject(error);
});

