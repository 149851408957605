import React, {useEffect, useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import {FaTimesCircle} from "react-icons/fa";
import { Input, Button, Space } from 'antd';
import {performAction} from "../Actions";
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DeleteButton } from './shared/DeleteButton';


function Validator() {

    const [validators, setValidators] = useState([])
    const [ruleSets, setRuleSets] = useState([])
    const [fieldSets, setFieldSets] = useState([])
    const [menuTypes, setMenuTypes] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAdd = () => {setEditFlag(false); handleShow(); resetForm()}
    const [refresh, setRefresh] = useState(false)

    // track form elements
    const [ruleSetId, setRuleSetId] = useState()
    const [fieldSetId, setFieldSetId] = useState()
    const [menuTypeId, setMenuTypeId] = useState()
    const [validatorId, setValidatorId] = useState()
    const [identifier, setIdentifier] = useState()
    const [editFlag, setEditFlag] = useState(false)

    const onRuleSetSelection = ({target:{value}}) => setRuleSetId(value)
    const onFieldSetSelection = ({target:{value}}) => setFieldSetId(value)
    const onMenuTypeSelection = ({target:{value}}) => setMenuTypeId(value)
    const resetForm = () => {
        setMenuTypeId()
        setFieldSetId()
        setRuleSetId()
        setIdentifier()
      }
    const handleEdit = async (e, validator) => {
        e.preventDefault()
        handleShow()
        setEditFlag(true)
        async function setForm() {
            setValidatorId(validator.id)
            setIdentifier(validator.identifier)
            setRuleSetId(validator.rule_set.id)
            setFieldSetId(validator.field_set.id)
            setMenuTypeId(validator.menu_type.id)
        }
        await setForm()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let payload = {
            "identifier": identifier,
        }

        if(menuTypeId !== "-1") payload["menu_type_id"] = menuTypeId
        if(ruleSetId !== "-1") payload["rule_set_id"] = ruleSetId
        if(fieldSetId !== "-1") payload["field_set_id"] = fieldSetId

        let action
        if(editFlag === true){
            payload["id"] = validatorId
            action = "update"
        } else {
            action = "create"
        }
        performAction('validator', action, payload).then(r => {
            setRefresh(true)
            setEditFlag(false)
            resetForm()
            handleClose()
        })
    }

    useEffect(() => {
        async function fetch_fieldsets() {
            let fieldsets_list = await performAction('field-set', 'list')
            setFieldSets(fieldsets_list)
        }
        fetch_fieldsets()
    }, [])

    useEffect(() => {
        async function fetch_rulesets() {
            let rulesets_list = await performAction('rule-set', 'list')
            setRuleSets(rulesets_list)
        }
        fetch_rulesets()
    }, [])

    useEffect(() => {
        async function fetch_menutypes() {
            let menutypes_list = await performAction('menu-type', 'list')
            setMenuTypes(menutypes_list)
        }
        fetch_menutypes()
    }, [])

    useEffect(() => {
        async function fetch_validators() {
            let validators_list = await performAction('validator', 'list')
            setValidators(validators_list)
        }
        fetch_validators().then(r => setRefresh(false))
    }, [refresh])

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    var searchInput = null
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    };
  
    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('')
    };
  
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
      });

    const getRow = (validator, index) => {
        return   {
          key: index,
          id: validator,
          identifier: validator.identifier,
          menuType: validator.menu_type.identifier,
          ruleSet: validator.rule_set.identifier,
          fieldSet: validator.field_set.description,
          action: validator.id,
        }
    }

    const columns = [
        {
          "title": 'UID',
          "dataIndex": 'id',
          "key": 'id',
          "render": validator => <a href="" onClick={(e) => handleEdit(e, validator)}>{validator.id}</a>
        },
        {
          "title": "Identifier",
          "dataIndex": "identifier",
          "key": "identifier",
          ...getColumnSearchProps('identifier'),
        },
        {
          "title": "Menu Type",
          "dataIndex": "menuType",
          "key": "menuType",
          "filters": menuTypes.map((menuType) => {return {"text": menuType.identifier, "value": menuType.identifier}}),
          "onFilter": (value, record) => record.menuType.includes(value),
        },
        {
          "title": "Ruleset",
          "dataIndex": "ruleSet",
          "key": "ruleSet",
        },
        {
          "title": "Fieldset",
          "dataIndex": "fieldSet",
          "key": "fieldSet",
        },
        {
          "title": "Action",
          "key": "action",
          "render": (validator) => <DeleteButton entity="validator" entity_label="Validator" entityId={validator.action} onSuccess={setRefresh}/>
        }
      ]
      const data = validators.map(getRow)


    return (
        <>
            <Row>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <h3>List of Validators</h3>
                    <Button size="sm" variant="outline-primary" onClick={handleAdd}>
                        Add Validator
                    </Button>
                </div>
                <Table columns={columns} dataSource={data}/>
            </Row>

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Validator</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Identifier</Form.Label>
                            <Form.Control value={identifier} placeholder="Enter identifier" onChange={(e) => setIdentifier(e.target.value)}/>
                            <Form.Text className="text-muted">
                                Eg : custom validator
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Select value={menuTypeId} onChange={onMenuTypeSelection}>
                                <option value="-1">Select Menu Type</option>
                                {menuTypes.map((menuType, idx) => {
                                    return <option value={menuType.id}>{menuType.identifier}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Select value={ruleSetId} onChange={onRuleSetSelection}>
                                <option value="-1">Select Ruleset</option>
                                {ruleSets.map((ruleset, idx) => {
                                    return <option value={ruleset.id}>{ruleset.identifier}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Select value={fieldSetId} onChange={onFieldSetSelection}>
                                <option value="-1">Select Fieldset</option>
                                {fieldSets.map((fieldSet, idx) => {
                                    return <option value={fieldSet.id}>{fieldSet.description}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Validator
