import React, {useEffect, useState, version} from 'react';

import Row from 'react-bootstrap/Row'
import {FaEllipsisV, FaArchive} from "react-icons/fa";
import {UnControlled as CodeMirror} from 'react-codemirror2-react-17'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import { getPaginatedData, performAction, fetchOperators, runTests, fetchVersions } from "../Actions";
import { Form, Input, Button, Space, Select, Table, Drawer, Tabs, notification, Tag, Steps, message, Collapse, Spin, Radio, Dropdown, Menu, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined, SearchOutlined, DeleteOutlined, ExclamationCircleOutlined, RollbackOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { API_OBJECT_TYPE, PUBLISHED_VERSION, ARCHIVED_VERSION, DRAFT_VERSION} from '../global_constants';

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const notificationMessages = {
    "success": "Rule successfully saved",
    "disable": "Rule successfully disabled",
    "delete": "Rule successfully deleted",
    "publish": "Rule successfully published",
    "error": "Some error occurred"
}

const openNotificationWithIcon = (type, title, desc) => {
    const msg = notificationMessages[title] || notificationMessages[type] || title
    notification[type]({
      message: msg,
    });
};



function Rule() {
    const [ simple_form ] = Form.useForm();
    const [ custom_form ] = Form.useForm();
    const [ fields, setFields ] = useState([])
    const [ is_new_version_creation, setIsNewVersionCreation ] = useState(true)
    const [ uniqueFieldIdentifiers, setUniqueFieldIdentifiers ] = useState([])
    const [ ruleTypes, setRuleTypes ] = useState([]);
    const [ fieldTypes, setFieldTypes ] = useState([]);
    const [ entityTypes, setEntityTypes ] = useState([]);
    const [ operators, setOperators ] = useState([])
    const [ rules, setRules ] = useState([]);
    const [ ruleSets, setRuleSets ] = useState([])
    const [ testExecution, setTestExecution ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    // menuLogs stores production payloads marked as test
    const [ menuLogs, setMenuLogs ] = useState([])
    const [ platformWiseMenuLogs, setPlatformWiseMenuLogs ] = useState({})
    // totalCount stores total number of test payloads that failed validation
    const [ totalCount, setTotalCount ] = useState([])
    const [ selectedRulesets, setSelectedRulesets ] = useState([])
    // menuLogTestInfo stores status of rule validation against test payloads
    const [ menuLogTestInfo, setMenuLogTestInfo ] = useState({})
    // ruleValidationStatus stores result of overall rule validation; by default set to True
    // It will be marked as false if there are any test payloads available for the selected rulesets
    const [ ruleValidationStatus, setRuleValidationStatus] = useState(true)
    const [modal, contextHolder] = Modal.useModal()
    const [messageApi, messageContextHolder] = message.useMessage();
    const [isRuleInCorrect, setIsRuleInCorrect] = useState(true)
    let rollbackRuleID = 0


    const handleShow = () => {
        setIsRuleInCorrect(true)
        setIsReadonly(false)
        setDrawerVisibility(true)
        simple_form.resetFields()
        custom_form.resetFields()
        setCode('def rule():\n    result = False\n    message = ""\n\n    # add your logic here\n\n    return result, message')
        setRuleId()
        setRuleStatus()
        setEditFlag(false)
        setCustomRule(false)
        setTestExecution(false)
    }
    const [refresh, setRefresh] = useState(false)
    
    // form elements
    const [ drawerVisibility, setDrawerVisibility ] = useState(false)
    const [code, setCode] = useState('')
    const [ruleId, setRuleId] = useState()
    const [ruleStatus, setRuleStatus] = useState()

    const [parentRuleId, setParentRuleId] = useState(0)

    // form state
    const [editFlag, setEditFlag] = useState(false)
    const [customRule, setCustomRule] = useState(false)
    const [isReadonly, setIsReadonly] = useState(false)

    const handleCustomRuleToggle = (key) => {
        if(key === "custom") setCustomRule(true)
        else setCustomRule(false)
    }

    const handleCreateNewVersion = (rule) => {
        const rule_details = rule.id
        const parent_id = rule_details.parent_id || rule_details.id
        setDrawerVisibility(true)
        setIsNewVersionCreation(true)
        const rule_kind = rule.kind || rule_details.rule_kind
        copyRuleDetails(rule_details, rule_kind)
        setParentRuleId(parent_id)
        setEditFlag(false)
        setTestExecution(false)
        setIsReadonly(false)
        setRuleStatus("")
        setRuleId("")
    }

    const onCodeChange = (editor, data, value) => { setCode(value) }

    const fetchEntityIdFromIdentifier = (identifier) => {
        let entityTypeId = null
        entityTypes.forEach((entityType, idx) => {
            if(entityType.identifier === identifier){
                entityTypeId = entityType.id
            }
        })
        return entityTypeId
    }

    const fetchFieldTypeIdFromIdentifier = (identifier) => {
        let fieldTypeId = null
        fieldTypes.forEach((fieldType) => {
            if(fieldType.identifier === identifier){
                fieldTypeId = fieldType.id
            }
        })
        return fieldTypeId
    }

    const fetchRuleTypeIdFromIdentifier = (identifier) => {
        let ruleTypeId = null
        ruleTypes.forEach((ruleType) => {
            if(ruleType.identifier === identifier){
                ruleTypeId = ruleType.id
            }
        })
        return ruleTypeId
    }


    const handleSubmit = (e, test=false) => {
        e.preventDefault()
        const form = customRule === true ? custom_form : simple_form

        form.validateFields().then(
            (values) => {
                if(Object.entries(values).length === 0 && values.constructor === Object){
                    values = customRule === true ? customFormDetails : simpleFormDetails
                }
                let type_specific_attrs = {}
                // form common part of payload for both simple & custom rule
                let payload = {
                    "error_message": values.errorMessage,
                    "error_level": values.errorLevel.toLowerCase(),
                    "rule_kind": customRule === true ? "custom" : "simple",
                    "rule_sets": selectedRulesets,
                }
                // then form type specific parts of payload
                if(customRule){
                    type_specific_attrs = {
                        "code": code,
                        "rule_type_id": fetchRuleTypeIdFromIdentifier(values.ruleType),
                        "entity_type_id": fetchEntityIdFromIdentifier(values.entityType),
                        "field_type_id": values.fieldType && fetchFieldTypeIdFromIdentifier(values.fieldType),
                    }
                } else {
                    // pick field type id & entity type id
                    // from field identifier as displayed in 
                    // field dropdown for each condition
                    let conditions = []
                    let entityTypeId = 0
                    let fieldTypeId = 0
                    values.multipleConditions.forEach((condition) => {
                        for(let field of fields){
                            if(prepareFieldIdentifier(field) == condition.field){
                                entityTypeId = field.entity_type.id
                                if(field.field_type){
                                    fieldTypeId = field.field_type.id
                                }
                                break
                            }
                        }
                        // form expected payload for conditions
                        conditions.push({
                            "operator": condition.operator,
                            "operand": condition.operand,
                            "entity_type_id": entityTypeId,
                            "field_type_id": fieldTypeId,
                            "rule_type_id": fetchRuleTypeIdFromIdentifier(values.ruleType),
                        })
                    })
                    type_specific_attrs = {
                        "conditions": conditions
                    }
                }
                const final_payload = {...payload, ...type_specific_attrs}

                if(test == true && Object.keys(menuLogTestInfo).length > 0){
                    setLoading(true)
                    runTests(Object.keys(menuLogTestInfo), final_payload).then(response => {
                        setLoading(false)
                        let validationReport = response["report"]
                        let newMenuLogTestInfo = menuLogTestInfo
                        let validationStatusWiseTestInfo = {}
                        let testStatus = "PASS"
                        let failedValidationCount = 0
                        setIsRuleInCorrect(response["is_rule_incorrect"])
                        for(let menuLogId in newMenuLogTestInfo){
                            if (validationReport[menuLogId]["status"] === false){
                                testStatus = "FAIL"
                                failedValidationCount += 1
                            }
                            newMenuLogTestInfo[menuLogId] = {
                                "status": validationReport[menuLogId]["status"] ? "PASS" : "FAIL",
                                "error_list": validationReport[menuLogId]["error_list"]
                            }
                            let platform = validationReport[menuLogId]["platform"]
                            if(platform in validationStatusWiseTestInfo){
                                validationStatusWiseTestInfo[platform].append({
                                    "status": validationReport[menuLogId]["status"] ? "PASS" : "FAIL",
                                    "error_list": validationReport[menuLogId]["error_list"]
                                })
                            }
                        }
                        if(testStatus === "PASS"){
                            setRuleValidationStatus(true)
                        } else {
                            setRuleValidationStatus(false)
                        }

                        setMenuLogTestInfo({...newMenuLogTestInfo})
                        setTestExecution(true)
                        setTotalCount(failedValidationCount)
                    }).catch(error => console.log(error, " is the error"))
                }
                else {
                    let action = ''
                    if(editFlag === true){
                        final_payload['id'] = ruleId
                        action = 'update'
                    } else {
                        if (parentRuleId) {
                            final_payload['parent_id'] = parentRuleId
                        }
                        action = 'create'
                    }

                    performAction('rule', action, final_payload).then (r => {
                        setRefresh(true)
                        setEditFlag(false)
                        setDrawerVisibility(false)
                        openNotificationWithIcon('success')
                    }).catch(error => console.log(error," is the error"))
                }

            }
        ).catch((errorInfo) => console.log(errorInfo," is errorInfo"))

    }

    const copyRuleDetails = async (rule, rule_kind) => {
        setCustomRule(rule_kind === 'custom')
        setSelectedRulesets(rule.rule_sets.map(rule_set => rule_set.id))
        const form = rule_kind === 'custom' ? custom_form : simple_form
        if(rule_kind === 'simple'){
            let rule_type = ''
            let multipleConditionsList = []
            rule.conditions && rule.conditions.forEach((condition) => {
                let field = prepareFieldIdentifier(condition.identifier)
                multipleConditionsList.push({
                    "field": field,
                    "operator": condition.operator,
                    "operand": condition.operand,
                })
                rule_type = condition.identifier.rule_type.identifier

            })

            const formValues = {
                "multipleConditions": multipleConditionsList,
                "errorLevel": rule.error_level,
                "errorMessage": rule.error_message,
                "ruleType": rule_type,
            }
            form.setFieldsValue(formValues)
        } else {
            const formValues = {
                "errorLevel": rule.error_level,
                "errorMessage": rule.error_message,
                "entityType": rule.identifier.entity_type.identifier,
                "fieldType": rule.identifier.field_type && rule.identifier.field_type.identifier,
                "ruleType": rule.identifier.rule_type.identifier,
            }
            form.setFieldsValue(formValues)
            setCode(rule.code)
        }
    }
    // handleEdit fills the form with the details of selected rule
    const handleEdit = async (e, rule) => {
        // set to true
        e.preventDefault()
        handleShow() // this opens the form
        setRuleId(rule.id)
        setParentRuleId(rule.parent_id)
        setEditFlag(true)
        setRuleStatus(rule.status)
        setIsReadonly(false)

        if ([ARCHIVED_VERSION, PUBLISHED_VERSION].includes(rule.status)) {
            setIsReadonly(true)
        }
        copyRuleDetails(rule, rule.rule_kind)
    }

    useEffect(() => {
        async function fetch_rule_types() {
            let ruletypes_list = await performAction('rule-type', 'list')
            setRuleTypes(ruletypes_list)
        }
        fetch_rule_types()
    }, [])

    useEffect(() => {
        // closing the drawer toggles drawerVisibility
        if(drawerVisibility === false)
            {
                setSelectedRulesets([])
                setMenuLogTestInfo({})
                setPlatformWiseMenuLogs({})
                setTestExecution(false)
            }
        setCurrentFormPage(0)
    }, [drawerVisibility])

    useEffect(() => {
        // fetch test payloads based on selected rulesets
        if(selectedRulesets.length > 0){
            // send offset & limit as -1 to avoid backend pagination
            const selectedFilters = {
                'offset': -1,
                'limit': -1,
                'ruleset_ids': selectedRulesets,
                'use_as_sample': true,
            }
            fetchMenuLogs(selectedFilters)
        }
        setTestExecution(false)
    }, [selectedRulesets])

    useEffect(() => {
        async function fetch_fields() {
            let fields_list = await performAction('field', 'list')
            setFields(fields_list)
            fields_list = fields_list.map((field) => prepareFieldIdentifier(field))
            let uniqueIdentifiers = [...new Set(fields_list)]
            setUniqueFieldIdentifiers(uniqueIdentifiers)
        }
        fetch_fields()
    }, [])

    useEffect(() => {
        async function fetch_entity_types() {
            let entities = await performAction('entity-type', 'list')
            setEntityTypes(entities)
        }
        fetch_entity_types()
    }, [])

    useEffect(() => {
        async function fetch_field_types() {
            let fieldtypes_list = await performAction('field-type', 'list')
            setFieldTypes(fieldtypes_list)
        }
        fetch_field_types()
    }, [])

    useEffect(() => {
        async function fetch_operators() {
            let operators_list = await fetchOperators()
            setOperators(operators_list)
        }
        fetch_operators()
    }, [])

    useEffect(() => {
        async function fetch_rules() {
            let rules_list = await performAction(`rule?status=${category}`, 'list')
            setRules(rules_list)
        }
        fetch_rules().then(r => setRefresh(false))
    }, [refresh])

    useEffect(() => {
        async function fetch_rulesets() {
            let rulesets_list = await performAction('rule-set', 'list')
            setRuleSets(rulesets_list)
        }
        fetch_rulesets()
    }, [])

    const color_map = {
        [PUBLISHED_VERSION]: "green",
        [DRAFT_VERSION]: "blue",
        [ARCHIVED_VERSION]: "grey"
    }
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    var searchInput = null

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    };
  
    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('')
    };
  
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
      });
    
    const handlePublish = (ruleId) => {
//        if (isRuleInCorrect) {
//            messageApi.info("You can't PUBLISH this rule. This rule doesn't pass all the sample payloads. Please retry running tests again");
//            return
//        }
        openConfirmActionDialog(ruleId, "publish")
    }

    const openConfirmActionDialog = (ruleId, action) => {
        const final_payload = {"id": ruleId}
        const message = `Are you sure you want to ${action} rule with ID`

        modal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: <span>{message} <b>{ruleId}</b>?</span>,
            onOk: () => {
                if (ruleStatus == ARCHIVED_VERSION && action == "publish") {
                    action = 'publish_new_version'
                    performAction('rule', action, final_payload).then (r => {
                        setDrawerVisibility(false)
                        if (r.success) {
                            openNotificationWithIcon('success', action)
                        } else {
                            openNotificationWithIcon('error')
                        }
                        setRefresh(true)
                    }).catch(error => console.log(error," is the error"))
                } else {
                    performAction('rule', action, final_payload).then (r => {
                        setDrawerVisibility(false)
                        if (r.success || r.id) {
                            openNotificationWithIcon('success', action)
                        } else {
                            openNotificationWithIcon('error')
                        }
                        setRefresh(true)
                    }).catch(error => console.log(error," is the error"))
                }
            }
        });
    }

    const createOptionsForVersions = (version_list) => {
        const version_options = []
        version_list.forEach( version => {
            version_options.push({
                'value': version.id,
                'label': `#${version.id}(v${version.version}) published at ${version.published_at}: \n
                    ${version.error_message}`,
                'title': `${version.error_message}`
            })
        })
        return version_options
    }
    const openRollbackDialog = async (ruleId) => {
        rollbackRuleID = 0
        const versions_list_resp = await fetchVersions('rule', ruleId)
        if (!versions_list_resp.success) {
            openNotificationWithIcon('info', 'You cannot ROLLBACK this rule as there are no previous versions')
            return 
        }
        //setIsModalOpen(true);
        modal.confirm({
            title: `Rollback action for rule with id ${ruleId}.`,
            icon: <RollbackOutlined />,
            width: 520,
            content: <Select
            style={{ width: 420 }}
            onChange={(value) => {rollbackRuleID = value}}
            defaultValue={null}
            placeholder="Select version. Hover option for error message"

            options={createOptionsForVersions(versions_list_resp.data)}
          />,
            onOk: async () => {
                const rules_list = await performAction(`rule?status=${ARCHIVED_VERSION}`, 'list')
                const selected_rule = rules_list.find(rule => rule.id == rollbackRuleID)
                handleCreateNewVersion({id: selected_rule})
            }
        });
    }

    const getRow = (rule, index) => {
        return   {
          key: index,
          id: rule,
          entityType: rule,
          fieldType: rule,
          ruleType: rule,
          errorLevel: rule.error_level,
          kind: rule.rule_kind,
          errorMessage: rule.error_message,
          ruleSet: rule,
          action: rule.id
        }
    }

    const getEntityColumn = (rule) => {
        if(rule.rule_kind === "simple"){
            return rule.conditions.map((condition) => {
                return <p>{condition.identifier.entity_type.identifier}</p>
            })
        } else {
            return <p>{rule.identifier.entity_type.identifier}</p>
        }
    }

    const getFieldColumn = (rule) => {
        if(rule.rule_kind === "simple"){
            return rule.conditions.map((condition) => {
                return <p>{condition.identifier.field_type && condition.identifier.field_type.identifier}</p>
            })
        } else {
            return <p>{rule.identifier.field_type && rule.identifier.field_type.identifier}</p>
        }
    }

    const getRuleTypeColumn = (rule) => {
        if(rule.rule_kind === "simple"){
            if(rule.conditions && rule.conditions.length > 0){
                return <p>{rule.conditions[0].identifier.rule_type.identifier}</p>
            }
        } else {
            return <p>{rule.identifier.rule_type.identifier}</p>
        }
    }

    const getRuleSetColumn = (rule) => {
        if(rule.rule_sets && (rule.rule_sets.length > 0)){
            return rule.rule_sets.map((rule_set) => {
                return <Tag>{rule_set.identifier}</Tag>
            })
        }
    }

    const filterEntityForSimpleRule = (value, record) => {
        let found = false
        if(record.entityType.conditions){
            record.entityType.conditions.forEach((condition) => {
                if(condition.identifier.entity_type.identifier.includes(value)){
                    found = true
                    return
                }
            })
        }
        return found
    }

    const filterFieldTypeForSimpleRule = (value, record) => {
        let found = false
        if(record.fieldType.conditions){
            record.fieldType.conditions.forEach((condition) => {
                if(condition.identifier.field_type && 
                    condition.identifier.field_type.identifier.includes(value)){
                    found = true
                    return
                }
            })
        }
        return found
    }

    const filterRuleTypeForSimpleRule = (value, record) => {
        let found = false
        if(record.ruleType.conditions){
            record.ruleType.conditions.forEach((condition) => {
                if(condition.identifier.rule_type.identifier.includes(value)){
                    found = true
                    return
                }
            })
        }
        return found
    }

    const filterRuleSet = (value, record) => {
        let found = false
        if(record.ruleSet.rule_sets){
            record.ruleSet.rule_sets.forEach((rule_set) => {
                if(rule_set.identifier.includes(value)){
                    found = true
                    return
                }
            })
        }
        return found
    }

    const fetchMenuLogs = async (selected_filters) => {
        setLoading(true)
        let menuLogsData = await getPaginatedData(API_OBJECT_TYPE.verification_requests, selected_filters);
        let menuLogsList = menuLogsData.rows_data
        let newMenuLogTestInfo = {}
        let platformWiseTestPayloads = {}

        for(let menuLog of menuLogsList){
            newMenuLogTestInfo[menuLog["id"]] = {
                "status": "NA",
                "error_list" : []
            }
            if(menuLog["platform"] in platformWiseTestPayloads){
                platformWiseTestPayloads[menuLog["platform"]].push(menuLog)
            } else {
                platformWiseTestPayloads[menuLog["platform"]] = [menuLog]
            }
        }

        if(menuLogsList.length > 0){
            setMenuLogs(menuLogsList)
        }
        setLoading(false)
        // initialise menuLogTestInfo with sample payloads
        // with status as "NA" that will be modified when tests are run
        setMenuLogTestInfo(newMenuLogTestInfo)
        setPlatformWiseMenuLogs(platformWiseTestPayloads)
    }

    const actions = [
        {
            key: '4',
            danger: true,
            label: 'a danger item',
          },
          {
            key: '1',
            danger: true,
            label: 'a danger 2 item',
          }
    ]

    const getMenuList = (rule_item) => {
        const rule_details = rule_item.id
        return (
            <Menu>
              <Menu.Item key="create" icon={<PlusOutlined/>} disabled={rule_details.status == DRAFT_VERSION} onClick={() => handleCreateNewVersion(rule_item)}>Create new version</Menu.Item>
              <Menu.Item key="disable" icon={<FaArchive/>} disabled={rule_details.status != PUBLISHED_VERSION} onClick={(e) => openConfirmActionDialog(rule_item.action, "disable")}>Disable</Menu.Item>
              <Menu.Item key="rollback" icon={<RollbackOutlined />} disabled={rule_details.status != PUBLISHED_VERSION} onClick={(e) => openRollbackDialog(rule_item.action)}>Rollback</Menu.Item>
              <Menu.Item key="delete" icon={<DeleteOutlined />} danger disabled={rule_details.status != DRAFT_VERSION} onClick={(e) => openConfirmActionDialog(rule_item.action, "delete")}>Delete</Menu.Item>
            </Menu>
          )
    }

    const columns = [
        {
          "title": 'UID',
          "dataIndex": 'id',
          "key": 'id',
          "render": rule => <a href="" onClick={(e) => handleEdit(e, rule)}>{rule.id}</a>
        },
        {
          "title": "Entity Type",
          "dataIndex": "entityType",
          "key": "entityType",
          "filters": entityTypes.map((entityType) => {return {"text": entityType.identifier, "value": entityType.identifier}}),
          "onFilter": (value, record) => record.entityType.rule_kind === 'custom' ? 
            record.entityType.identifier.entity_type.identifier.includes(value) : filterEntityForSimpleRule(value, record),
        //   "onFilter": (value, record) => filterEntity(value, record),
          "sorter": (a, b) => a.entityType.localeCompare(b.entityType),
          "render": (rule) => getEntityColumn(rule),
        },
        {
          "title": "Field Type",
          "dataIndex": "fieldType",
          "key": "fieldType",
          "filters": fieldTypes.map((fieldType) => {return {"text": fieldType.identifier, "value": fieldType.identifier}}),
        //   "onFilter": (value, record) => record.fieldType.includes(value),
          "onFilter": (value, record) => record.fieldType.rule_kind === 'custom' ? 
          (record.fieldType.identifier.field_type && record.fieldType.identifier.field_type.identifier.includes(value)) : filterFieldTypeForSimpleRule(value, record),
          "sorter": (a, b) => a.fieldType.localeCompare(b.fieldType),
          "render": (rule) => getFieldColumn(rule),
        },
        {
          "title": "Rule Type",
          "dataIndex": "ruleType",
          "key": "ruleType",
          "filters": ruleTypes.map((ruleType) => {return {"text": ruleType.identifier, "value": ruleType.identifier}}),
        //   "onFilter": (value, record) => record.ruleType.includes(value),
        "onFilter": (value, record) => record.ruleType.rule_kind === 'custom' ? 
        (record.ruleType.identifier.rule_type.identifier.includes(value)) : filterRuleTypeForSimpleRule(value, record),
          "render": (rule) => getRuleTypeColumn(rule),
        },
        {
          "title": "Error Level",
          "dataIndex": "errorLevel",
          "key": "errorLevel",
          "filters": [
              {"text": "Error", "value": "error"},
              {"text": "Warning", "value": "warning"},
          ],
          "onFilter": (value, record) => record.errorLevel.includes(value),
        },
        {
          "title": "Kind",
          "dataIndex": "kind",
          "key": "kind",
          "filters": [
            {"text": "Simple", "value": "simple"},
            {"text": "Custom", "value": "custom"},
        ],
        "onFilter": (value, record) => record.kind.includes(value),
        },
        {
          "title": "Error Message",
          "dataIndex": "errorMessage",
          "key": "errorMessage",
          ...getColumnSearchProps('errorMessage')
        },
        {
            "title": "Rulesets",
            "dataIndex": "ruleSet",
            "key": "ruleSet",
            "filters": ruleSets.map((ruleSet) => {return {"text": ruleSet.identifier, "value": ruleSet.identifier}}),
            "onFilter": (value, record) => filterRuleSet(value, record),
            "render": (rule) => getRuleSetColumn(rule),
        },
        {
            "title": "Status",
            "dataIndex": "status",
            "key": "status",
            "render": (rule, record) => {
                const status = record?.id?.status
                return (
                    <Space>
                        <Tag color={color_map[status] || "red"}>{status.toUpperCase()}</Tag>
                        <Tag color="blue">{"v" + record?.id?.version}</Tag>
                    </Space>
                    )
            }
        },
        {
          "title": "Action",
          "key": "action",
          "dataIndex": "operation",
          "render": (rule, record) => {
            return (
                <Dropdown overlay={getMenuList(record)} placement='bottom'>
                    <FaEllipsisV style={{cursor: 'pointer'}}/>
                </Dropdown>
            )
          }
        }
      ]

    const data = rules.map(getRow)

    const menuLogColumns = [
        {
            "title": 'UID',
            "dataIndex": 'id',
            "key": 'id',
            "render": id => <Link to={`/logs-details/${id}/payload`} target="_blank">{id}</Link>
        },
        {
            "title": 'Platform',
            "dataIndex": 'platform',
            "key": 'platform',
            "render": platform => <p> {platform} </p>
        },
        {
            "title": 'Status',
            "dataIndex": 'status',
            "key": 'status',
            "render": (id) => <p>{id in menuLogTestInfo && menuLogTestInfo[id]["status"]}</p>
        },
    ]

    const getMenuLogRow = (menuLog, index) => {
        return {
          key: index,
          id: menuLog.id,
          platform: menuLog.platform,
          status: menuLog.id,
        }
    }
    const menuLogData = menuLogs.filter((menuLog) => menuLog.id in menuLogTestInfo && menuLogTestInfo[menuLog.id]["status"] === "FAIL").map(getMenuLogRow)

    const RuleDetails =
    <Tabs onChange={handleCustomRuleToggle} activeKey={customRule ? "custom" : "simple"}>
        <TabPane tab="Simple" key="simple">
        <Form name="simple-rule-form"
            form={simple_form}
            layout="vertical"
            disabled>

            <Form.List name="multipleConditions">
                {(items, {add, remove}) => (
                    <>
                        {items.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    name={[name, 'field']}
                                    rules={[{ required: true, message: 'Field is mandatory' }]}
                                    >
                                    <Select
                                        allowClear
                                        readOnly={isReadonly}
                                        style={{ minWidth: '250px' }}
                                        placeholder="Select Field">
                                        {uniqueFieldIdentifiers.map((identifier) => {
                                            return <Option key={identifier}>{identifier}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'operator']}
                                    rules={[{ required: true, message: 'Operator is mandatory' }]}
                                    >
                                    <Select
                                        allowClear
                                        readOnly={isReadonly}
                                        style={{ minWidth: '200px' }}
                                        placeholder="Select Operator">
                                        {Object.entries(operators).map((operator, idx) => {
                                            return <Option key={operator[1]}>{operator[1]}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'operand']}
                                    rules={[{ required: false }]}
                                    >
                                    <Input placeholder="Enter value" readOnly={isReadonly} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" readOnly={isReadonly} onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Condition
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item name="errorLevel"
                label="Error Level">
                <Select
                    allowClear
                    readOnly={isReadonly}
                    placeholder="Select Error Level">
                    {["Error", "Warning"].map((errorLevel, idx) => {
                        return <Option key={errorLevel}>{errorLevel}</Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item name="errorMessage" label="Error Message">
                <Input placeholder="Enter error message" readOnly={isReadonly}/>
            </Form.Item>
            <Form.Item name="ruleType"
                label="Rule Type">
                <Select
                    readOnly={isReadonly}
                    allowClear
                    placeholder="Select Rule Type">
                    {ruleTypes.map((ruleType, idx) => {
                        return <Option key={ruleType.identifier}>{ruleType.identifier}</Option>
                    })}
                </Select>
            </Form.Item>
        </Form>
        </TabPane>
        <TabPane tab="Custom" key="custom">
        <CodeMirror
            value={code}
            options={{
                mode: {
                    name: "python",
                    version: 3,
                    singleLineStringErrors: false
                },
                lineNumbers: true,
                indentUnit: 4,
                matchBrackets: true,
                theme: 'material',
            }}
            onChange={onCodeChange}
        />
        <Form name="custom-rule-form"
            form={custom_form}
            layout="vertical"
            style={{marginTop:"20px"}}
            >

                <Form.Item name="errorLevel"
                    label="Error Level">
                    <Select
                        allowClear
                        readOnly={isReadonly}
                        placeholder="Select Error Level">
                        {["Error", "Warning"].map((errorLevel, idx) => {
                            return <Option key={errorLevel}>{errorLevel}</Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="errorMessage" label="Error Message">
                    <Input placeholder="Enter error message" readOnly={isReadonly}/>
                </Form.Item>

                <Form.Item name="entityType"
                    label="Entity Type">
                    <Select
                        allowClear
                        readOnly={isReadonly}
                        placeholder="Select Entity Type">
                        {entityTypes.map((entityType, idx) => {
                            return <Option key={entityType.identifier}>{entityType.identifier}</Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="fieldType"
                    label="Field Type">
                    <Select
                        allowClear
                        readOnly={isReadonly}
                        placeholder="Select Field Type">
                        {fieldTypes.map((fieldType, idx) => {
                            return <Option key={fieldType.identifier}>{fieldType.identifier}</Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="ruleType"
                    label="Rule Type">
                    <Select
                        allowClear
                        readOnly={isReadonly}
                        placeholder="Select Rule Type">
                        {ruleTypes.map((ruleType, idx) => {
                            return <Option key={ruleType.identifier}>{ruleType.identifier}</Option>
                        })}
                    </Select>
                </Form.Item>
        </Form>
        </TabPane>
    </Tabs>

    const spinLoader =
        <Spin tip="Loading..." size="large">
            <div className="content" />
        </Spin>

    const AssociateRule =
    <div>
        {messageContextHolder}
        <div style={{marginBottom:"50px"}}>
            <h5>Select Rulesets</h5>
            <Select
                mode="multiple"
                allowClear
                disabled={parentRuleId || is_new_version_creation}
                defaultValue={selectedRulesets.map(id => id.toString())}
                style={{ minWidth: '250px' }}
                onChange={vals => setSelectedRulesets(vals.map(val => val))}
                placeholder="Select Rulesets">
                {ruleSets.map((ruleSet) => {
                    return <Option key={ruleSet.id}>{ruleSet.identifier}</Option>
                })}
            </Select>
            <Button disabled={Object.keys(menuLogTestInfo).length==0} onClick={(e) => handleSubmit(e, true)}>
                        Run Tests
            </Button>
        </div>
        {
            loading === true ? spinLoader :
        <div>
            {
                Object.keys(menuLogs).length > 0 ?
                (   testExecution ?
                    (
                        ruleValidationStatus === false ?
                        (   <Table //
                            columns={menuLogColumns}
                            dataSource={menuLogData}
                            pagination={{
                                total: totalCount
                              }}
                            // onChange={handleMenuLogChange}
                            expandable={{
                                expandedRowRender: record =>
                                    menuLogTestInfo && menuLogTestInfo[record.id]["error_list"].map((err_obj, idx) => {
                                        return <p>ID : {err_obj["ref_id"]}, Name : {err_obj["name"]}</p>
                                }),
                                rowExpandable: record =>
                                    record.id in menuLogTestInfo && menuLogTestInfo[record.id]["status"] != 'NA' &&
                                    menuLogTestInfo[record.id]["status"] != 'PASS',
                            }}
                        /> ) :
                        (
                            <p>Validation passed for all test payloads</p>
                        )
                    ) :
                    (<Collapse bordered={false}>
                    {
                    Object.keys(platformWiseMenuLogs).map((platform, index) =>
                        <Panel
                            header={`${platformWiseMenuLogs[platform].length} test payloads available for ${platform}`}
                            key={index}>
                                {platformWiseMenuLogs[platform].map((menuLog) => {
                                    return <span key={index}><Link to={`/logs-details/${menuLog.id}/payload`} target="_blank">{menuLog.id}</Link> </span>
                                })}
                        </Panel>
                    )
                    }
                    </Collapse>)
                 ) :
                <h6>No test payloads available for selected rulesets! :(</h6>
            }
        </div>
        }
    </div>


    const steps = [
        {
            "title": "Build Rule",
            "content": RuleDetails,
        },
        {
            "title": "Associate Rule",
            "content": AssociateRule,
        }
    ]

    const [ currentFormPage, setCurrentFormPage ] = useState(0);
    const [ simpleFormDetails, setSimpleFormDetails ] = useState({})
    const [ customFormDetails, setCustomFormDetails ] = useState({})
    const next = () => {
        setCurrentFormPage(currentFormPage + 1);
        const form = customRule === true ? custom_form : simple_form
        let customFormData = custom_form.getFieldsValue()
        let simpleFormData = simple_form.getFieldsValue()
        setSimpleFormDetails(simpleFormData)
        setCustomFormDetails(customFormData)
    };
      const prev = () => {
        setCurrentFormPage(currentFormPage - 1);
    };

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const is_last_step = currentFormPage === steps.length - 1 ? true : false
    const disable_submit_button = is_last_step && (ruleValidationStatus === false)

    const [category, setCategory] = useState(PUBLISHED_VERSION);
    const categoryChange = (e) => {
        setCategory(e.target.value);
        setRefresh(true)
    };
    

    return (
        <>
            <Row>
                <div>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <h3>List of Rules</h3>
                        <Button icon={<PlusOutlined />} onClick={() => {setIsNewVersionCreation(false); setParentRuleId(null); handleShow()}}>
                            Add Rule
                        </Button>

                    </div>
                    <Radio.Group value={category} onChange={categoryChange} style={{margin:"10px 0px", cursor: 'pointer'}}>
                        <Radio.Button value="ALL">All</Radio.Button>
                        <Radio.Button value={PUBLISHED_VERSION}>Published</Radio.Button>
                        <Radio.Button value={DRAFT_VERSION}>Draft</Radio.Button>
                        <Radio.Button value={ARCHIVED_VERSION}>Disabled</Radio.Button>
                    </Radio.Group>
                </div>
                <Table columns={columns} dataSource={data}/>
            </Row>
            <Drawer 
                title={`${editFlag ? "Update Rule": is_new_version_creation ? "Add rule version": "Add Rule"}`}
                width={700}
                onClose={(e) => setDrawerVisibility(false)}
                visible={drawerVisibility}
                extra={
                    <Space>
                        { ruleId && <Tag color={color_map[ruleStatus]}>{ruleStatus.toUpperCase()}</Tag>}
                      <Button onClick={(e) => setDrawerVisibility(false)}>Cancel</Button>
                      {currentFormPage > 0 && <Button onClick={() => prev()}>Previous</Button>}
                      {is_last_step && ruleStatus == DRAFT_VERSION && ruleId && <Button onClick={() => { handlePublish(ruleId)}}>Publish</Button>}
                      <Button block onClick={is_last_step ? handleSubmit : next} type="primary" htmlType="submit" disabled={is_last_step && isReadonly}>
                        {is_last_step ? "Submit" : "Next"} 
                      </Button>
                    </Space>
                  }
            >
                <Steps current={currentFormPage} items={items}/>
                <div className="steps-content">{steps[currentFormPage].content}</div>

            </Drawer>

            {contextHolder}
        </>
    )
}

export default Rule


function prepareFieldIdentifier(field) {
    let fieldIdentifier = field.entity_type.identifier
    if(field.field_type){
        fieldIdentifier += "-" + field.field_type.identifier
    }
    return fieldIdentifier
}