import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row'
import { Table } from 'antd';
import { Input, Button, Space, Switch } from 'antd';
import { performAction, markPayloadAsSample, getPaginatedData } from '../Actions';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { API_OBJECT_TYPE} from '../global_constants';

const platforms = ["swiggy", "zomato", "justeat", "deliveroo", "talabat", "amazon"]
const verification_statuses = ["success", "failed", "error"]


function MenuType() {

  const [menuLogs, setMenuLogs] = useState([])
  const [validators, setValidators] = useState([])
  const [totalCount, setTotalCount] = useState([])
  const [ currentFilters, setCurrentFilters ] = useState({'offset': 0, 'limit': 10})

  useEffect(() => {
    async function getMenuLogs() {
      const current_page = 1
      let menuLogsData = await getPaginatedData(API_OBJECT_TYPE.verification_requests)
      let menuLogsList = menuLogsData.rows_data
      let total_count = menuLogsData.total_count
      setMenuLogs(menuLogsList)
      setTotalCount(total_count)
    }
    getMenuLogs()
  }, [])

  useEffect(() => {
    async function fetch_validators() {
        let validatorsList = await performAction('validator', 'list')
        setValidators(validatorsList)
    }
    fetch_validators()
  }, [])

  const handleChange = async (pagination, filters, sorter) => {
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const selected_filters = {'offset': offset, 'limit': limit};
    const search_filter_keys = ['biz_location_name', 'biz_id', 'biz_location_id', 'biz_name', 'menu_id']

    Object.keys(filters).forEach(key => {
      if (filters[key] != null && filters[key] != undefined) {
        if (search_filter_keys.includes(key)) {
          selected_filters[key] = filters[key][0]
        } else {
          selected_filters[key] = filters[key]
        }
      }
    });

    // store updated filters in currentFilters so that when payloads are marked as sample
    // we can refresh the page while keeping the applied filters/pagination intact.
    setCurrentFilters(selected_filters)

    await fetchPaginatedMenuLogs(selected_filters)
  };

  const fetchPaginatedMenuLogs = async (selected_filters) => {
    let menuLogsData = await getPaginatedData(API_OBJECT_TYPE.verification_requests, selected_filters);
    let menuLogsList = menuLogsData.rows_data
    let total_count = menuLogsData.total_count
    setMenuLogs(menuLogsList)
    setTotalCount(total_count)
  };

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  var searchInput = null
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
          />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            >
            Search
          </Button>
          <Button onClick={()=>{clearFilters();handleSearch(selectedKeys, confirm, dataIndex)}} 
            size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',
    });
  
  const addSampleSelection = async (selectedKey, event) => {
    await markPayloadAsSample(selectedKey, event)
    await fetchPaginatedMenuLogs(currentFilters)
  }

    const getRow = (menuLog, index) => {
      return   {
        key: index,
        id: index + 1,
        biz_id: menuLog.biz_id,
        biz_name: menuLog.biz_name,
        biz_location_id: menuLog.biz_location_id,
        biz_location_name: menuLog.biz_location_name,
        platform: menuLog.platform,
        status: menuLog.verification_status,
        validator: menuLog.validator,
        created_at: menuLog.created_at,
        action: menuLog,
        use_as_sample: menuLog.use_as_sample,
        uuid: menuLog.uuid,
        menu_id: menuLog.menu_id
      }
    }
  
    const columns = [
      {
        "title": 'ID',
        "dataIndex": 'id',
        "key": 'id',
      },
      {
        "title": "Biz ID",
        "dataIndex": "biz_id",
        "key": "biz_id",
        ...getColumnSearchProps('biz_id')
      },
      {
        "title": "Biz Name",
        "dataIndex": "biz_name",
        "key": "biz_name",
        ...getColumnSearchProps('biz_name')
      },
      {
        "title": "Biz Location ID",
        "dataIndex": "biz_location_id",
        "key": "biz_location_id",
        ...getColumnSearchProps('biz_location_id')
      },
      {
        "title": "Biz Location Name",
        "dataIndex": "biz_location_name",
        "key": "biz_location_name",
        ...getColumnSearchProps('biz_location_name')
      },
      {
        "title": "Platform",
        "dataIndex": "platform",
        "key": "platform",
        "filters": platforms.map((platform) => {return {"text": platform, "value": platform}}),
        "onFilter": (value, record) => record.platform.toLowerCase().includes(value),
      },
      {
        "title": "Validation Status",
        "dataIndex": "status",
        "key": "status",
        "filters": verification_statuses.map((status) => {return {"text": status, "value": status}}),
        "onFilter": (value, record) => record.status.toLowerCase().includes(value),
      },
      {
        "title": "Validator",
        "dataIndex": "validator",
        "key": "validator",
        "filters": validators.map((validator) => {return {"text": validator.identifier, "value": validator.identifier}}),
        "onFilter": (value, record) => record.validator.toLowerCase().includes(value),
      },
      {
        "title": "Menu ID",
        "dataIndex": "menu_id",
        "key": "menu_id",
        ...getColumnSearchProps('menu_id')
      },
      {
        "title": "Validated At",
        "dataIndex": "created_at",
        "key": "created_at",
        ...getColumnSearchProps('created_at')
      },
      {
        "title": "Action",
        "key": "action",
        "render": (record) => 
        <div>
            <Link to={`/logs-details/${record.action.id}/payload`} target="_blank">View Payload</Link>
            <br/>
            <Link to={`/logs-details/${record.action.id}/report`} target="_blank">View Report</Link>
        </div>
      },
      {
        "title": "Use as Sample",
        "key": "use_as_sample",
        "filters": [{"text": "Test payloads", "value": true}, {"text": "Non test payloads", "value": false}],
        "onFilter": (value, record) => record.use_as_sample === value,
        "render": (record) =>
        <div>
            <Switch
              checked={record.use_as_sample}
              onChange={e => addSampleSelection(record.uuid, e)}/>
        </div>
      }
    ]
  
    const data = menuLogs.map(getRow)

  return (
      <>
        <Row>
          <div>
            <h3>List of Validation Requests</h3>
          </div>
          <Table 
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={{
              total: totalCount // total count returned from backend
            }}
          />
        </Row>
      </>
  )
}

export default MenuType
