
import { DeleteTwoTone } from '@ant-design/icons'
import { performAction } from '../../Actions';
import { Modal, notification } from 'antd';
import { useState } from 'react';

export function DeleteButton(props) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const notificationMessages = {
        "success": `${props.entity_label} deleted successfully`,
        "error": "Some error occurred"
    }

    const openNotificationWithIcon = (type) => {
        const msg = notificationMessages[type]
        notification[type]({
          message: msg,
        });
    };
    const handleCancel = () => {
        setIsModalOpen(false)
    };
    const handleOk = () => {
        performAction(props.entity, "delete", {id: props.entityId}).then(r => {
            if (r.success || r.id) {
                openNotificationWithIcon('success')
            } else {
                openNotificationWithIcon('error')
            }
            props.onSuccess()
        }).catch(error => {
            openNotificationWithIcon('error')
            handleCancel()
            console.log(error," is the error")
        })
    }
    const msg = `Are you sure you want to DELETE?`;

    return (
        <>
        <DeleteTwoTone onClick={()=> setIsModalOpen(true)} twoToneColor="#e30e23"/>
        <Modal
            title={msg}
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Yes"
            cancelText="No">
        <p>{props.entity_label} with UID <b>#{props.entityId}</b></p>
        </Modal>
        </>
    )
}
