import React, {useEffect, useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import {FaTimesCircle} from "react-icons/fa";
import { Input, Button, Space } from 'antd';
import {performAction} from '../Actions';
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DeleteButton } from './shared/DeleteButton';


function RuleType() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [identifier, setIdentifier] = useState('');
  const [description, setDescription] = useState('');
  const [refresh, setRefresh] = useState(false)
  const [ruleTypes, setRuleTypes] = useState([])
  const [ruleTypeId, setRuleTypeId] = useState()
  const [editFlag, setEditFlag] = useState(false)
  
  const onIdentifierChange = ({target:{value}}) => setIdentifier(value)
  const onDescriptionChange = ({target:{value}}) => setDescription(value)
  const resetForm = () => {
    setIdentifier('')
    setRuleTypeId()
    setDescription('')
  }
  const handleAdd = () => {setEditFlag(false); handleShow(); resetForm()}
  const handleEdit = async (e, ruleType) => {
    e.preventDefault()
    handleShow()
    setEditFlag(true)
    async function setForm() {
      setRuleTypeId(ruleType.id)
      setIdentifier(ruleType.identifier)
      setDescription(ruleType.description)
    }
    await setForm()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      "identifier": identifier,
      "description": description,
    }
    let action
    if(editFlag === true){
      payload["id"] = ruleTypeId
      action = "update"
    } else {
      action = "create"
    }
    performAction('rule-type', action, payload).then(r => {
      setRefresh(true)
      resetForm()
      setEditFlag(false)
      handleClose()
    })
  }

  useEffect(() => {
    async function getRuleTypes() {
      let ruletypes_list = await performAction('rule-type', 'list')
      setRuleTypes(ruletypes_list)
    }
    getRuleTypes().then(r => setRefresh(false))
  }, [refresh])

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  var searchInput = null
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
          />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',
    });


  const getRow = (ruleType, index) => {
    return   {
      key: index,
      id: ruleType,
      identifier: ruleType.identifier,
      description: ruleType.description,
      action: ruleType.id,
    }
  }

  const columns = [
    {
      "title": 'UID',
      "dataIndex": 'id',
      "key": 'id',
      "render": ruleType => <a href="" onClick={(e) => handleEdit(e, ruleType)}>{ruleType.id}</a>
    },
    {
      "title": "Identifier",
      "dataIndex": "identifier",
      "key": "identifier",
      ...getColumnSearchProps('identifier')
    },
    {
      "title": "Description",
      "dataIndex": "description",
      "key": "description"
    },
    {
      "title": "Action",
      "key": "action",
      "render": (ruleType) => <DeleteButton entity="rule-type" entity_label="RuleType" entityId={ruleType.action} onSuccess={setRefresh}/>
    }
  ]

  const data = ruleTypes.map(getRow)



  return (
      <>
        <Row>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>List of Rule Types</h3>
            <Button size="sm" variant="outline-primary" onClick={handleAdd}>
              Add Rule Type
            </Button>
          </div>
          <Table columns={columns} dataSource={data}/>
        </Row>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Rule Type</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Identifier</Form.Label>
                <Form.Control type="text" value={identifier} placeholder="Enter identifier" onChange={onIdentifierChange}/>
                <Form.Text className="text-muted">
                  Eg : title_length_constraints
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" value={description} placeholder="Enter description" onChange={onDescriptionChange}/>
                <Form.Text className="text-muted">
                  Eg : Rules regarding title length of menu elements.
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </>
  )
}

export default RuleType
