import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {fetchDataForMenuLog} from '../Actions';
import ReactJson from 'react-json-view'


function MenuDetailsType() {

  const [menuLogDetails, setMenuLogDetails] = useState([])
  const { id, type } = useParams();

  useEffect(() => {
    async function getMenuLogDetails() {
      let menuLogDetailsList = await fetchDataForMenuLog(id, type)
      setMenuLogDetails(menuLogDetailsList)
    }
    getMenuLogDetails()

  }, [])

  return (
      <>
        <h3>{type == 'report' ? "Validation Report": "Validation Payload"}</h3>
        <div><ReactJson src={menuLogDetails}/></div>
      </>
  )
}

export default MenuDetailsType
