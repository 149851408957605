import React, {useEffect, useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import {FaTimesCircle} from "react-icons/fa";
import { Table } from 'antd';
import { Input, Button, Space } from 'antd';
import {performAction} from '../Actions';
import { SearchOutlined } from '@ant-design/icons';
import { DeleteButton } from './shared/DeleteButton';


function MenuType() {

  const [menuTypes, setMenuTypes] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [identifier, setIdentifier] = useState('');
  const [title, setTitle] = useState('');
  const [refresh, setRefresh] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [menuTypeId, setMenuTypeId] = useState()
  
  const onIdentifierChange = ({target:{value}}) => setIdentifier(value)
  const onTitleChange = ({target:{value}}) => setTitle(value)
  const resetForm = () => {
    setIdentifier('')
    setMenuTypeId()
    setTitle('')
  }
  const handleAdd = () => {setEditFlag(false); handleShow(); resetForm()}
  const handleEdit = async (e, menuType) => {
    e.preventDefault()
    handleShow()
    setEditFlag(true)
    async function setForm() {
      setMenuTypeId(menuType.id)
      setIdentifier(menuType.identifier)
      setTitle(menuType.title)
    }
    await setForm()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      "identifier": identifier,
      "title": title,
    }
    let action;
    if(editFlag === true){
      payload["id"] = menuTypeId
      action = "update"
    } else {
      action = "create"
    }
    performAction('menu-type', action, payload).then(r => {
      setRefresh(true)
      setEditFlag(false)
      resetForm()
      handleClose()
    })
  }

  useEffect(() => {
    async function getMenuTypes() {
      let menutypes_list = await performAction('menu-type', 'list')
      setMenuTypes(menutypes_list)
    }
    getMenuTypes().then(r => setRefresh(false))
  }, [refresh])

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  var searchInput = null
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
          />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',
    });

    const getRow = (menuType, index) => {
      return   {
        key: index,
        id: menuType,
        identifier: menuType.identifier,
        title: menuType.title,
        action: menuType.id,
      }
    }
  
    const columns = [
      {
        "title": 'UID',
        "dataIndex": 'id',
        "key": 'id',
        "render": menuType => <a href="" onClick={(e) => handleEdit(e, menuType)}>{menuType.id}</a>
      },
      {
        "title": "Identifier",
        "dataIndex": "identifier",
        "key": "identifier",
        ...getColumnSearchProps('identifier')
      },
      {
        "title": "Title",
        "dataIndex": "title",
        "key": "title"
      },
      {
        "title": "Action",
        "key": "action",
        "render": (menuType) => <DeleteButton entity="menu-type" entity_label="MenuType" entityId={menuType.action} onSuccess={setRefresh}/>
      }
    ]
  
    const data = menuTypes.map(getRow)

  return (
      <>
        <Row>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <h3>List of Menu Types</h3>
            <Button size="sm" variant="outline-primary" onClick={handleAdd}>
              Add Menu Type
            </Button>
          </div>
          <Table columns={columns} dataSource={data}/>
        </Row>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{editFlag ? "Edit" : "Add"} Menu Type</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Identifier</Form.Label>
                <Form.Control type="text" value={identifier} placeholder="Enter identifier" onChange={onIdentifierChange}/>
                <Form.Text className="text-muted">
                  Eg : zomato_v2, swiggy_access, justeat
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" value={title} placeholder="Enter title" onChange={onTitleChange}/>
                <Form.Text className="text-muted">
                  Eg : Zomato V2, Swiggy Access, Justeat
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </>
  )
}

export default MenuType
