import React from 'react';


function Home() {
    return (
      <div>
        <h5>An overview of the concepts involved</h5>
        <br/>
        <article>
          <h5>Entity Types</h5>
          <p>These are root level elements of a menu such as items, options, option groups, categories, taxes & charges.</p>
        </article>
        <article>
          <h5>Field Types</h5>
          <p>These are attributes of root level elements of a menu, such as price, title etc, which can belong to multiple entity types.
             <br/> Once 
          </p>
        </article>
        <article>
          <h5>Menu Types</h5>
          <p>These are different types of menu belonging to different aggregators.</p>
        </article>
        <article>
          <h5>Fields</h5>
          <p>These are instantiated versions of entity types & field types, linking an conceptual element with 
            its real life counterpart via the access path of the element in the payload.</p>
        </article> 
        <article>
          <h5>Fieldsets</h5>
          <p>These are collection of fields, like collection of zomato fields, swiggy fields.</p>
        </article>
        <article>
          <h5>RuleTypes</h5>
          <p>These are categories of rules, that can be used if there's need to exclude certain type of rules from being validated.</p>
        </article>
        <article>
          <h5>Rules</h5>
          <p>These are rules written in python, using the identifiers of entity & field types. As long as these have been instantiated in form of fields and have correct path,
             the backend would be able to match these identifiers with the path & execute the rule logic.
          </p>
        </article>
        <article>
          <h5>Rulesets</h5>
          <p>These are collection of rules, that can be universal or specific to a platform.
          </p>
        </article>
        <article>
          <h5>Validators</h5>
          <p>This links a ruleset, fieldset & menu type together & used to validate rules for a platform or platform - biz combination.
          </p>
        </article>
        <article>
          <h5>Menu Validation</h5>
          <p>This is a utility through which a json menu can be verified against some rules after selecting the appropriate validator.
          </p>
        </article>
      </div>
    );
  }

export default Home


